import React from "react"
const SectionTitle = ({
  children,
  theme,
  size = "is-size-1 is-size-1-mobile",
  additionalClasses,
  subtitle,
}) => {
  const textClass = theme === "light" ? "has-text-black" : "has-text-white"
  return (
    <header>
      <h2
        className={`${size}  ${textClass} has-text-weight-bold ${additionalClasses}`}
      >
        {children}
      </h2>
    </header>
  )
}

export default SectionTitle
