import React from "react"

const Columns = ({ children, mobile = false }, additionalClasses = "") => {
  const multiLineClass = mobile ? "is-multiline" : ""
  const classes = additionalClasses !== "" ? additionalClasses : ""
  return (
    <div className={`columns ${classes} ${multiLineClass}`}>{children}</div>
  )
}

export default Columns
