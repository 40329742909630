import React from "react"

const Column = ({ children, size = {}, additionalClasses = "" }) => {
  const sizeClass = getSizeClass(size)
  const classes = additionalClasses !== "" ? additionalClasses : ""
  return <div className={`column ${sizeClass} ${classes}`}>{children}</div>
}

const getSizeClass = size => {
  var sizes = ""
  sizes += size.all ? "is-" + size.all + " " : ""
  sizes += size.desktop ? "is-" + size.desktop + "-desktop " : ""
  sizes += size.mobile ? "is-" + size.mobile + "-mobile " : ""
  sizes += size.touch ? "is-" + size.touch + "-touch " : ""
  return sizes
}

export default Column
