import { useStaticQuery, graphql } from "gatsby"

export const useOptions = () => {
  const { wp } = useStaticQuery(
    graphql`
      query OPTION {
        wp {
          siteGeneralSettings {
            websiteTermsAndConditions {
              websiteTermsAndConditions
            }
          }
        }
      }
    `
  )
  return wp.siteGeneralSettings
}
