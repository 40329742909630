import React, { useEffect } from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import "aos/dist/aos.css"
//import { parse } from "tldts"

/* Indie Components */
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import MountainHeader from "../components/MountainHeader"
import PageWrapper from "../components/PageWrapper"
import PageTitleSection from "../components/PageTitleSection"
import Section from "../components/Section"
import SectionTitle from "../components/SectionTitle"
import BodyText from "../components/BodyText"
import Columns from "../components/Columns"
import Column from "../components/Column"

import { useOptions } from "../hooks/use-options"

const DesignAndDevelopmentTermsPage = ({ data: { page } }) => {
  let options = useOptions()

  useEffect(() => {
    const offset = window.innerWidth > 500 ? 360 : 180
    const AOS = require("aos")
    AOS.init({
      duration: 700,
      offset: offset,
      disable: function () {
        return /bot|googlebot|crawler|spider|robot|crawling/i.test(
          navigator.userAgent
        )
      },
    })

    if (AOS) {
      AOS.refresh()
    }
  }, [])

  const {
    seo,
    pageSettings: { showFooter },
    headerSettings: { headerImages, title, subTitle },
    homepageSettings: {
      intro: { title: introTitle, content: introContent },
      featuredProject: { title: projectTitle },
      whatWeDo: { title: whatWeDoTitle, content: whatWeDoContent },
    },
  } = page

  return (
    <Layout showMenu={false} showFooter={showFooter} page={page}>
      <Seo seo={seo} />
      <MountainHeader headerImages={headerImages} />
      <PageWrapper additionalClassName="home">
        <PageTitleSection
          title="Terms & Conditions:<br>For Website Design & Development"
          subtitle=""
        />

        <Section dataAos="fade-up" additionalClasses="double-top-padding">
          <Columns mobile={true}>
            <Column size={{ all: 9 }}>
              <BodyText
                text=""
                weight="bold"
                padding={true}
                size="is-size-5 is-size-3-mobile"
              />
              <div className="content has-text-white">
                <p className="is-size-4">Last Revised: 23/11/2022</p>
                {options.websiteTermsAndConditions.websiteTermsAndConditions &&
                  parse(
                    options.websiteTermsAndConditions.websiteTermsAndConditions
                  )}
              </div>
            </Column>
          </Columns>
        </Section>
      </PageWrapper>
    </Layout>
  )
}

export default DesignAndDevelopmentTermsPage

export const pageQuery = graphql`
  query TermsHomePageById {
    # selecting the current post by id
    page: wpPage(id: { eq: "cG9zdDozNQ==" }) {
      # use common page fields from fragment @ src/fragments.js
      ...pageFields
      homepageSettings {
        featuredProject {
          title
        }
        intro {
          title
          content
          fieldGroupName
          link {
            ... on WpPage {
              id
              uri
            }
          }
        }
        whatWeDo {
          title
          content
          fieldGroupName
          link {
            ... on WpPage {
              id
              uri
            }
          }
        }
      }
    }
  }
`
